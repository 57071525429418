



































































































































































































import Vue from 'vue';
import LoadingIndicator from '@/components/shared/LoadingIndicator.vue';
import EmptyDataset from '@/components/shared/EmptyDataset.vue';
import FetchFailure from '@/components/shared/FetchFailure.vue';
import DateInput from '@/components/shared/forms/DateInput.vue';
import SelectInput from '@/components/shared/forms/SelectInput.vue';
import { Payment, PaymentMethod } from '@/model/payment';
import I18n from '@/config/i18n';
import reportsService from '@/services/reports.service';
import moment from 'moment';
import { API_DATE_FORMAT } from '@/constants';
import { mapStores } from 'pinia';
import { useUiStore } from '@/store/ui-store';

export default Vue.extend({
  components: {
    LoadingIndicator,
    EmptyDataset,
    FetchFailure,
    DateInput,
    SelectInput
  },
  data() {
    return {
      today: moment().toDate(),
      isFetching: false,
      isDownloading: false,
      errorOccurred: false,
      reportData: {
        range: [
          moment().format(API_DATE_FORMAT),
          moment()
            .subtract(1, 'days')
            .format(API_DATE_FORMAT)
        ]
      } as { range: [string, string]; method?: string },
      payments: [] as Payment[],
      paymentsSelected: [] as Payment[],
      paymentMethodOptions: [
        { value: null, label: I18n.t('label.allPaymentMethod') },
        { value: PaymentMethod.CARD, label: I18n.t('label.card') },
        { value: PaymentMethod.POSTCARD, label: I18n.t('label.postcard') },
        { value: PaymentMethod.CASH, label: I18n.t('label.cash') },
        { value: PaymentMethod.GIFT, label: I18n.t('label.gift') }
      ]
    };
  },
  computed: {
    ...mapStores(useUiStore),
    totalPaymentsAmount(): number {
      const total = 0;
      return this.payments.reduce(
        (accumulator, payment) => accumulator + payment.amount,
        total
      );
    },
    totalSelectedPaymentsAmount(): number {
      const total = 0;
      return this.paymentsSelected.reduce(
        (accumulator, payment) => accumulator + payment.amount,
        total
      );
    },
    difference(): number {
      return this.totalPaymentsAmount - this.totalSelectedPaymentsAmount;
    }
  },
  mounted() {
    this.getPaymentsReport();
  },
  methods: {
    selectAllPayments() {
      if (this.paymentsSelected.length === 0) {
        this.paymentsSelected = this.payments;
      } else {
        this.paymentsSelected = [];
      }
    },
    selectPayment(payment: Payment) {
      if (
        this.paymentsSelected.find(
          currenctPayment => payment.id === currenctPayment.id
        )
      ) {
        this.paymentsSelected = this.paymentsSelected.filter(
          (currentPayment: any) => {
            return currentPayment.id !== payment.id;
          }
        );
      } else {
        this.paymentsSelected = (this.paymentsSelected as any).concat([
          payment
        ]);
      }
    },
    isPaymentSelected(payment: Payment) {
      return (
        this.paymentsSelected.find(
          currenctPayment => payment.id === currenctPayment.id
        ) !== undefined
      );
    },
    async getPaymentsReport() {
      this.isFetching = true;
      try {
        this.payments = await reportsService.findPaymentsReport(
          this.reportData
        );
        this.errorOccurred = false;
        this.isFetching = false;
      } catch (exception) {
        this.isFetching = false;
        this.errorOccurred = true;
      }
    },
    async getPaymentsReportAsExcel(ext: string) {
      this.isDownloading = true;
      try {
        const response = await reportsService.findPaymentsReportAsExcel(
          this.reportData,
          ext
        );
        const type =
          ext === 'xlsx'
            ? 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            : 'text/csv';
        const blob = new Blob([response.data], {
          type
        });
        const fileName = `payments_${moment().format('YYYYMMDDHHmmss')}.${ext}`;
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);
        link.click();
        this.isDownloading = false;
      } catch (exception) {
        this.isDownloading = false;
        this.uiStore.alert(exception.message);
      }
    }
  }
});

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dashboard-content"},[_c('div',{staticClass:"dashboard-header"},[_c('div',{staticClass:"dashboard-header-title"},[_vm._v(_vm._s(_vm.$t('sidebar.cashClosing')))])]),_c('div',{staticClass:"dashboard-card pb-1"},[_c('div',{staticClass:"md:tw-flex md:tw-justify-between"},[_c('form',{staticClass:"md:tw-flex md:tw-items-center tw-gap-4",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('date-input',{attrs:{"name":"start","value":_vm.reportData.range,"max-date":_vm.today,"mode":"range"},on:{"onValueChange":function (value) {
              _vm.reportData.range = value;
              _vm.getPaymentsReport();
            }}}),_c('select-input',{attrs:{"name":"method","options":_vm.paymentMethodOptions,"allow-empty":false},on:{"onValueChange":function (value) {
              _vm.reportData.method = value;
              _vm.getPaymentsReport();
            }}})],1),(!_vm.isFetching && _vm.payments.length > 0)?_c('div',{staticClass:"tw-hidden md:tw-flex md:tw-justify-end tw-gap-4"},[_c('b-navbar-nav',[_c('b-dropdown',{attrs:{"no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('i',{staticClass:"fas fa-download m-0"})]},proxy:true}],null,false,399288911)},[_c('b-dropdown-item',{staticClass:"tw-text-gray-600",on:{"click":function($event){return _vm.getPaymentsReportAsExcel('xlsx')}}},[_c('i',{staticClass:"fas fa-file-excel"}),_vm._v(" "+_vm._s(_vm.$t('reports.download.excel'))+" ")]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.getPaymentsReportAsExcel('csv')}}},[_c('i',{staticClass:"fas fa-file-csv"}),_vm._v(" "+_vm._s(_vm.$t('reports.download.csv'))+" ")])],1)],1)],1):_vm._e()]),(_vm.isDownloading)?_c('div',{staticClass:"tw-absolute tw-top-0 tw-left-0 tw-h-full tw-w-full tw-bg-white tw-opacity-80"},[_c('loading-indicator',{staticClass:"tw-sticky tw-left-1/2 tw-top-1/3"})],1):_vm._e(),(!_vm.isFetching && _vm.errorOccurred)?_c('fetch-failure'):_vm._e(),(!_vm.isFetching && _vm.payments.length === 0 && !_vm.errorOccurred)?_c('empty-dataset',{attrs:{"icon":"cash-register"}}):_vm._e(),(!_vm.isFetching && _vm.payments.length > 0)?_c('div',[_c('div',{staticClass:"tw-bg-light tw-px-4 tw-py-4 tw-rounded"},[_c('div',{staticClass:"tw-grid tw-grid-cols-1 tw-gap-5",class:{
            'sm:tw-grid-cols-1': _vm.totalSelectedPaymentsAmount === 0,
            'sm:tw-grid-cols-2 lg:tw-grid-cols-3':
              _vm.totalSelectedPaymentsAmount > 0
          }},[_c('div',{staticClass:"tw-px-4 tw-py-5 tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden sm:tw-p-6"},[_c('dt',{staticClass:"tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate"},[_vm._v(" "+_vm._s(_vm.$t('reports.total'))+" ")]),_c('dd',{staticClass:"tw-mt-1 tw-text-3xl tw-font-semibold tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalPaymentsAmount))+" ")])]),(_vm.totalSelectedPaymentsAmount > 0)?_c('div',{staticClass:"tw-px-4 tw-py-5 tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden sm:tw-p-6"},[_c('dt',{staticClass:"tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate"},[_vm._v(" "+_vm._s(_vm.$t('reports.selected'))+" ")]),_c('dd',{staticClass:"tw-mt-1 tw-text-3xl tw-font-semibold tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.totalSelectedPaymentsAmount))+" ")])]):_vm._e(),(_vm.totalSelectedPaymentsAmount > 0)?_c('div',{staticClass:"tw-hidden lg:tw-block tw-px-4 tw-py-5 tw-bg-white tw-shadow tw-rounded-lg tw-overflow-hidden sm:tw-p-6"},[_c('dt',{staticClass:"tw-text-sm tw-font-medium tw-text-gray-500 tw-truncate"},[_vm._v(" "+_vm._s(_vm.$t('reports.difference'))+" ")]),_c('dd',{staticClass:"tw-mt-1 tw-text-3xl tw-font-semibold tw-text-gray-900"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.difference))+" ")])]):_vm._e()])]),_c('div',{staticClass:"table my-4"},[_c('div',{staticClass:"table-row table-titles"},[_c('div',{staticClass:"table-title table-title-checkbox",on:{"click":_vm.selectAllPayments}},[_c('i',{staticClass:"tw-text-base tw-align-middle",class:{
                'fas fa-minus-circle tw-text-primary-500':
                  _vm.paymentsSelected.length < _vm.payments.length &&
                  _vm.paymentsSelected.length > 0,
                'fal fa-circle': _vm.paymentsSelected.length === 0,
                'fas fa-check-circle tw-text-primary-500':
                  _vm.paymentsSelected.length === _vm.payments.length
              }})]),_c('div',{staticClass:"table-title"},[_vm._v(" "+_vm._s(_vm.$t('label.created'))+" ")]),_c('div',{staticClass:"table-title"},[_vm._v(_vm._s(_vm.$t('label.paymentMethod')))]),_c('div',{staticClass:"table-title tw-text-left md:tw-text-right"},[_vm._v(" "+_vm._s(_vm.$t('label.amount'))+" ")])]),_vm._l((_vm.payments),function(payment){return _c('div',{key:payment.id,staticClass:"table-row table-hover",class:_vm.isPaymentSelected(payment) ? 'tw-text-primary-500' : '',on:{"click":function($event){return _vm.selectPayment(payment)}}},[_c('div',{staticClass:"d-none d-md-block"},[_c('i',{staticClass:"tw-align-middle",class:_vm.isPaymentSelected(payment)
                  ? 'fas fa-check-circle tw-text-primary'
                  : 'fal fa-circle tw-text-gray-600'})]),_c('div',{staticClass:"table-column-title-sm"},[_vm._v(" "+_vm._s(_vm.$t('label.created'))+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm._f("date")(payment.created,'DD.MM.YY HH:mm'))+" ")]),_c('div',{staticClass:"table-column-title-sm"},[_vm._v(" "+_vm._s(_vm.$t('label.paymentMethod'))+" ")]),_c('div',[_vm._v(" "+_vm._s(payment.method)+" ")]),_c('div',{staticClass:"table-column-title-sm"},[_vm._v(" "+_vm._s(_vm.$t('label.amount'))+" ")]),_c('div',{staticClass:"tw-text-left lg:tw-text-right"},[_vm._v(" "+_vm._s(_vm._f("currency")(payment.amount))+" ")])])})],2)]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
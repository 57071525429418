import Vue, { PropType } from 'vue';

export default Vue.extend({
  props: {
    name: {
      type: String,
      required: true
    },
    errors: {
      type: Object,
      default: () => ({})
    },
    /**
     * Permet au composant de remonter la valeur correctement typée (cas RadioInput et CheckboxInput pour lesquelles value est toujours une string mais on aimerait remonter une valeur bool ou number au parent)
     */
    valueDataType: {
      type: String as PropType<'string' | 'number' | 'boolean'>,
      default: 'string',
      required: false
    }
  },
  computed: {
    association(): string {
      if (this.name.indexOf('.') > -1) {
        return this.name.split('.')[0];
      } else {
        return '';
      }
    },
    associationField(): string {
      if (this.name.indexOf('.') > -1) {
        if (!isNaN(parseInt(this.name.split('.')[1], 10))) {
          return this.name.split('.')[2];
        } else {
          return this.name.split('.')[1];
        }
      } else {
        return '';
      }
    },
    associationIndex(): string {
      if (
        this.name.indexOf('.') > -1 &&
        !isNaN(parseInt(this.name.split('.')[1], 10))
      ) {
        return this.name.split('.')[1];
      } else {
        return '';
      }
    },
    isInvalid(): boolean {
      // Permet d'aller dans les associations
      if (this.association) {
        return !!(
          this.errors &&
          this.errors[this.association] &&
          this.errors[this.association][this.associationIndex][
            this.associationField
          ]
        );
      }
      return !!(this.errors && this.errors[this.name]);
    },
    errorMessage(): string | null {
      if (this.isInvalid) {
        // Permet d'aller dans les associations
        if (this.association) {
          return this.errors[this.association][this.associationIndex][
            this.associationField
          ][
            Object.keys(
              this.errors[this.association][this.associationIndex][
                this.associationField
              ]
            )[0]
          ];
        } else {
          return this.errors[this.name][Object.keys(this.errors[this.name])[0]];
        }
      } else {
        return null;
      }
    }
  },
  methods: {
    castValueAccordingToValueDataType(
      value: string
    ): string | boolean | number {
      if (this.valueDataType === 'boolean') {
        return value === '1';
      } else if (this.valueDataType === 'number') {
        return parseInt(value);
      } else {
        return value;
      }
    }
  }
});

import apiService from '@/services/api.service';

export default {
  async findPaymentsReport(postData: {
    range: [string, string];
    method?: string;
  }) {
    return (await apiService.post('reports/payments.json', postData)).data.data;
  },
  findPaymentsReportAsExcel(
    postData: {
      range: [string, string];
      method?: string;
    },
    ext: string
  ) {
    return apiService.downloadFile(`reports/payments.${ext}`, postData);
  }
};
